<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.displayName"
  >
    <vs-modal
      ref="modal2FA"
      backdropBlur
      :title="$t('modals.configurate2FA.title')"
      v-if="canChange2FA"
    >
      <div class="w-100 text-center">
        <p>
          {{ $t("modals.configurate2FA.description1") }}
        </p>
        <img :src="qrCode" />
        <p>
          {{ $t("modals.configurate2FA.description2") }}
        </p>
      </div>
      <div class="w-100 flex">
        <vs-input class="mr-3 w-full" v-model="otpPIN" />
        <vs-button @click="validateOTP">
          {{ $t("modals.configurate2FA.button") }}</vs-button
        >
      </div>
    </vs-modal>
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
        <small>{{ $t("currentUserMenu.available") }}</small>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="py-2 px-4">
            <div class="w-full">{{ $t("currentUserMenu.menus.locale") }}</div>
            <v-select
              label="alias"
              :options="
                Object.keys($t('locales')).map((key) => ({
                  id: key,
                  alias: $t(`locales.${key}`),
                }))
              "
              :reduce="(value) => (value ? value.id : value)"
              :clearable="false"
              :value="myData.locale || 'es'"
              class="mb-4 md:mb-0 w-full"
              name="userLocale"
              @input="onChangeLocale"
            />
            <vs-divider border-style="dashed" color="primary" />
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="toggleEmailNotifications"
          >
            <feather-icon
              :icon="
                !myData.dontReceiveEmails ? 'CheckSquareIcon' : 'SquareIcon'
              "
              svgClasses="w-4 h-4"
            />
            <span class="ml-2 whitespace-no-wrap">
              {{ $t("currentUserMenu.menus.emailNotifications") }}
            </span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="toggle2FA"
            v-if="canChange2FA"
          >
            <feather-icon
              :icon="myData.enable2FA ? 'CheckSquareIcon' : 'SquareIcon'"
              svgClasses="w-4 h-4"
            />
            <span class="ml-2 whitespace-no-wrap">
              {{ $t("currentUserMenu.menus.use2FA") }}
            </span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2 whitespace-no-wrap">
              {{ $t("currentUserMenu.menus.closeSession") }}
            </span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { db, functions } from "@/firebase/firebaseConfig";
import VsModal from "@vuesimple/vs-modal";
import vSelect from "vue-select";
import { Validator } from "vee-validate";

export default {
  components: {
    VsModal,
    vSelect,
  },
  data() {
    return { myData: {}, qrCode: null, otpPIN: null };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    canChange2FA() {
      return ["manager", "admin"].includes(
        this.$store.state.AppActiveUser.role
      );
    },
  },
  created() {
    this.$bind(
      "myData",
      db.collection("users").doc(this.$store.state.AppActiveUser.uid)
    );
  },
  methods: {
    toggleEmailNotifications() {
      let currentDontReceiveEmails = this.myData.dontReceiveEmails;
      db.collection("users")
        .doc(this.$store.state.AppActiveUser.uid)
        .update({ dontReceiveEmails: !currentDontReceiveEmails });
    },
    onChangeLocale(locale) {
      this.$i18n.locale = locale;
      Validator.localize(locale);
      db.collection("users")
        .doc(this.$store.state.AppActiveUser.uid)
        .update({ locale });
      this.$store.dispatch("updateUserInfo", { locale });
    },
    async toggle2FA() {
      let current2FA = this.myData.enable2FA;
      this.qrCode = null;
      if (!current2FA) {
        //Si no tiene 2FA aún, mandamos a que valide
        let generateOTPSecret = functions.httpsCallable("generateOTPSecret");

        const { data } = await generateOTPSecret();
        this.qrCode = data.code;
        this.$refs.modal2FA.open();
      } else {
        db.collection("users")
          .doc(this.$store.state.AppActiveUser.uid)
          .update({ enable2FA: false });
      }
    },
    async validateOTP() {
      let validateOTP = functions.httpsCallable("validateOTP");

      const { data } = await validateOTP({
        otp: this.otpPIN,
      });

      if (data) {
        db.collection("users")
          .doc(this.$store.state.AppActiveUser.uid)
          .update({ enable2FA: true });
        this.$refs.modal2FA.close();
      }
    },
    logout() {
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("public");
      localStorage.removeItem("userInfo");
      this.$store.commit("RESET_STATE", { root: true });
    },
  },
};
</script>
