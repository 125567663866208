/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default (user) => {
  //Esto no debería pasar, pero si pasa (está loguado con la versión antigua) ponemos el tipo "penal" asociado al usuario
  if (!user.availableTypes) {
    user.availableTypes = ["penal"];
  }

  const penalDashboardMenu = {
    header: "Dashboards",
    icon: "HomeIcon",
    i18n: "menus.dashboards",
    items: [
      {
        url: "/dashboard/cases",
        name: "Dashboard",
        slug: "dashboard-cases",
        icon: "BarChartIcon",
        i18n: "menus.submenus.analytics",
      },
    ],
  };

  const penalMenu = {
    header: "Penal",
    icon: "PackageIcon",
    i18n: user.availableTypes.length > 1 ? "menus.penalCases" : "menus.main",
    items: [
      {
        url: "/main/cases",
        name: "Cases",
        slug: "main-cases",
        icon: "ArchiveIcon",
        i18n: "menus.submenus.cases",
      },
      {
        url: "/dashboard/issues",
        name: "IssuesDashboard",
        slug: "dashboard-issues",
        icon: "PieChartIcon",
        i18n: "menus.submenus.issuesAnalytics",
      },
      {
        url: "/main/issues",
        name: "Issues",
        slug: "main-issues",
        icon: "ShieldIcon",
        i18n: "menus.submenus.issues",
      },
      {
        url: "/main/calendar",
        name: "Calendar",
        slug: "main-calendar",
        icon: "CalendarIcon",
        tagColor: "success",
        i18n: "menus.submenus.calendar",
      },
      {
        url: "/main/search",
        name: "Search",
        slug: "main-search",
        icon: "SearchIcon",
        tagColor: "success",
        i18n: "menus.submenus.advancedSearch",
      },
      {
        url: "/main/teaching",
        name: "Teaching",
        slug: "main-teaching",
        icon: "LifeBuoyIcon",
        tagColor: "success",
        i18n: "menus.submenus.teaching",
      },
    ],
  };

  if (user.availableTypes.includes("penal") && user.availableTypes.length > 1) {
    //Si la posibilidad de ver casos penales y no sólo casos penales, entonces debemos mostrar el dashboard bajo el submenu de penal
    penalMenu.items.splice(0, 0, ...penalDashboardMenu.items);
  }

  const civilMenu = {
    header: "Civil",
    icon: "PackageIcon",
    i18n: user.availableTypes.length > 1 ? "menus.civilCases" : "menus.main",
    items: [
      {
        url: "/civil/civilCases",
        name: "CivilCases",
        slug: "civil-cases",
        icon: "ArchiveIcon",
        i18n: "menus.submenus.cases",
      },
      {
        url: "/civil/calendar",
        name: "CivilCalendar",
        slug: "civil-calendar",
        icon: "CalendarIcon",
        tagColor: "success",
        i18n: "menus.submenus.calendar",
      },
    ],
  };

  const settingsMenu = {
    header: "Settings",
    icon: "PackageIcon",
    i18n: "menus.settings",
    items: [
      ...(["admin", "manager"].includes(user.role)
        ? [
            {
              url: "/settings/users",
              name: "Users",
              slug: "settings-users",
              icon: "UserIcon",
              i18n: "menus.submenus.users",
            },
            {
              url: "/settings/customers",
              name: "Customers",
              slug: "settings-customers",
              icon: "TargetIcon",
              i18n: "menus.submenus.customers",
            },
          ]
        : []),
      {
        url: "/settings/establishments",
        name: "Establishments",
        slug: "settings-establishments",
        icon: "ShoppingCartIcon",
        i18n: "menus.submenus.establishments",
      },
      {
        url: "/settings/zones",
        name: "Zones",
        slug: "settings-zones",
        icon: "MapPinIcon",
        i18n: "menus.submenus.zones",
      },
      ...(["admin", "manager"].includes(user.role)
        ? [
            {
              url: "/settings/lawyers",
              name: "Lawyers",
              slug: "settings-lawyers",
              icon: "BriefcaseIcon",
              i18n: "menus.submenus.lawyers",
            },
            {
              url: "/settings/witnesses",
              name: "Witnesses",
              slug: "settings-witnesses",
              icon: "BookIcon",
              i18n: "menus.submenus.witnesses",
            },
            {
              url: "/settings/courts",
              name: "Courts",
              slug: "settings-courts",
              icon: "AwardIcon",
              i18n: "menus.submenus.courts",
            },
          ]
        : []),
      {
        url: "/settings/bands",
        name: "Bands",
        slug: "settings-bands",
        icon: "UsersIcon",
        i18n: "menus.submenus.bands",
      },
      {
        url: "/settings/criminals",
        name: "Criminals",
        slug: "settings-criminals",
        icon: "FrownIcon",
        i18n: "menus.submenus.criminals",
      },
      {
        url: "/settings/vehicles",
        name: "Vehicles",
        slug: "settings-vehicles",
        icon: "TruckIcon",
        i18n: "menus.submenus.vehicles",
      },
    ],
  };

  let menu = [].concat(
    user.availableTypes.includes("penal") ? penalMenu : [],
    user.availableTypes.includes("civil") ? civilMenu : []
  );

  if (["admin", "manager", "supercustomer", "customer"].includes(user.role)) {
    if (
      user.availableTypes.includes("penal") &&
      user.availableTypes.length === 1
    ) {
      //Si podemos ver los casos penales y sólo los casos penales, entonces mostramos el dashboard como algo aparte
      menu.splice(0, 0, penalDashboardMenu);
    }

    menu.push(settingsMenu);
  }

  return menu;
};
